import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../components/Container';
import Layout from '../../components/Layout';
import styles from './wikiPage.module.scss';
import Stack from '../../components/Stack';
import formatDate from '../../utils/formatDate';

export default function wikiPage({ data: { contentfulWikiPage: data } }) {
  return (
    <Layout className={styles.container} title={data.title}>
      <Container className={styles.content}>
        <h1 className={styles.title}>{data.title}</h1>
        <p>
          Last updated @{' '}
          <span className={styles.date}>
            {formatDate(new Date(data.updatedAt))}
          </span>
        </p>
        <Stack className={styles.stack} spacing='component'>
          {data.wikisections.map(({ title, slug, content }) => (
            <section key={slug}>
              <h2 className={styles.subtitle}>{title}</h2>
              <div
                className={styles.mdx}
                dangerouslySetInnerHTML={{
                  __html: content.childMarkdownRemark.html,
                }}
              />
            </section>
          ))}
        </Stack>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    contentfulWikiPage(slug: { eq: $slug }) {
      title
      updatedAt
      wikisections {
        title
        slug
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
